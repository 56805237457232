"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const action_1 = require("@src/app/services/action");
const nod = (x, y) => {
    if (y > x)
        return nod(y, x);
    if (!y)
        return x;
    return nod(y, x % y);
};
class PercentageCtrl {
    constructor($scope, $filter, ConfigService, actionService) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.ConfigService = ConfigService;
        this.actionService = actionService;
        this.calcList = [
            {
                key: '1',
                value: this.$filter('translate')('What is X% of Y?'),
                postfix: ''
            }, {
                key: '2',
                value: this.$filter('translate')('X is what % of Y?'),
                postfix: '%'
            }, {
                key: '3',
                value: this.$filter('translate')('What is X plus Y%?'),
                postfix: ''
            }, {
                key: '4',
                value: this.$filter('translate')('What is X minus Y%?'),
                postfix: ''
            }, {
                key: '5',
                value: this.$filter('translate')('% increase from X to Y'),
                postfix: ''
            }, {
                key: '6',
                value: this.$filter('translate')('% decrease from X to Y'),
                postfix: ''
            }, {
                key: '7',
                value: this.$filter('translate')('% change from X to Y'),
                postfix: '%'
            }, {
                key: '8',
                value: this.$filter('translate')('% difference between X and Y'),
                postfix: '%'
            }, {
                key: '9',
                value: this.$filter('translate')('X is Y% of what?'),
                postfix: ''
            }, {
                key: '10',
                value: this.$filter('translate')('What is X times Y%?'),
                postfix: ''
            }, {
                key: '11',
                value: this.$filter('translate')('What is X divided by Y%?'),
                postfix: ''
            }, {
                key: '12',
                value: this.$filter('translate')('X% as a decimal'),
                postfix: ''
            }, {
                key: '13',
                value: this.$filter('translate')('X% as a fraction'),
                postfix: ''
            }, {
                key: '14',
                value: this.$filter('translate')('What plus X% is Y?'),
                postfix: ''
            }, {
                key: '15',
                value: this.$filter('translate')('What minus X% is Y?'),
                postfix: '%'
            }
        ];
        this.calc = this.calcList[0];
        this.result = {};
    }
    $onInit() {
        const calcKey = localStorage.getItem(`${this.constructor.name}_calcKey`);
        if (calcKey) {
            this.setCalc(calcKey);
        }
    }
    setCalc(key) {
        this.formClear();
        for (const calc of this.calcList) {
            if (calc.key === key) {
                localStorage.setItem(`${this.constructor.name}_calcKey`, key);
                this.calc = Object.assign({}, calc);
                break;
            }
        }
    }
    calcSubmit($form) {
        if ($form.$valid) {
            let result = {
                a: this.$scope.calcData.a,
                b: this.$scope.calcData.b,
                value: undefined
            };
            switch ($form.$name) {
                case 'calc1Form': {
                    result.value = this.$scope.calcData.a * this.$scope.calcData.b / 100;
                    break;
                }
                case 'calc2Form': {
                    result.value = this.$scope.calcData.a / this.$scope.calcData.b * 100;
                    break;
                }
                case 'calc3Form': {
                    result.value = this.$scope.calcData.a + this.$scope.calcData.a * this.$scope.calcData.b / 100;
                    break;
                }
                case 'calc4Form': {
                    result.value = this.$scope.calcData.a - this.$scope.calcData.a * this.$scope.calcData.b / 100;
                    break;
                }
                case 'calc5Form': {
                    result.value = (this.$scope.calcData.b - this.$scope.calcData.a) * 100 / this.$scope.calcData.a;
                    break;
                }
                case 'calc6Form': {
                    result.value = (this.$scope.calcData.a - this.$scope.calcData.b) * 100 / this.$scope.calcData.a;
                    break;
                }
                case 'calc7Form': {
                    result.value = ((this.$scope.calcData.b - this.$scope.calcData.a) / this.$scope.calcData.a) * 100;
                    break;
                }
                case 'calc8Form': {
                    result.value = 100 * Math.abs(this.$scope.calcData.a - this.$scope.calcData.b) /
                        ((this.$scope.calcData.a + this.$scope.calcData.b) / 2);
                    break;
                }
                case 'calc9Form': {
                    result.value = this.$scope.calcData.a * 100 / this.$scope.calcData.b;
                    break;
                }
                case 'calc10Form': {
                    result.value = this.$scope.calcData.a * (this.$scope.calcData.b * this.$scope.calcData.a / 100);
                    break;
                }
                case 'calc11Form': {
                    result.value = this.$scope.calcData.a / (this.$scope.calcData.b * this.$scope.calcData.a / 100);
                    break;
                }
                case 'calc12Form': {
                    result.value = this.$scope.calcData.a / 100;
                    break;
                }
                case 'calc13Form': {
                    const divider = nod(this.$scope.calcData.a, 100);
                    result.value = `${this.$scope.calcData.a / divider} / ${100 / divider}`;
                    break;
                }
                case 'calc14Form': {
                    result.value = this.$scope.calcData.b / (1 + this.$scope.calcData.a / 100);
                    break;
                }
                case 'calc15Form': {
                    result.value = (this.$scope.calcData.b * 100) / (100 - this.$scope.calcData.a);
                    break;
                }
            }
            this.result = Object.assign({}, result);
        }
        else {
            console.log($form.$error);
        }
    }
    formClear($form) {
        this.result = {
            a: undefined,
            b: undefined,
            value: undefined,
        };
        this.$scope.calcData = {};
        if ($form && $form.$name) {
            $form.$setPristine();
            $form.$setUntouched();
        }
    }
    copy(id) {
        if (this.result != undefined) {
            let copyValue;
            if (ng.isNumber(this.result.value))
                copyValue = this.$filter('number')(this.result.value, 10);
            else
                copyValue = this.result.value;
            this.actionService.copyFactory(document.getElementById(id), copyValue);
        }
    }
}
PercentageCtrl.$inject = ['$scope', '$filter', 'ConfigService', 'ActionService'];
const appModule = ng.module('app');
appModule.component('gamePercentage', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: PercentageCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.service('ActionService', action_1.ActionService);
appModule.directive('transInter', ['$compile', ($compile) => {
        return {
            restrict: 'E',
            transclude: true,
            priority: -1,
            scope: {
                transDefault: '@',
                translateAttr: '='
            },
            link: (scope, instanceElement, instanceAttributes, controller, transclude) => {
                const trans_template$ = new rxjs_1.Subject();
                const mutationCallback = (mutationsList) => {
                    for (const mutation of mutationsList) {
                        trans_template$.next(mutation.target.getAttribute("trans-template"));
                    }
                };
                const observer = new MutationObserver(mutationCallback);
                observer.observe(instanceElement[0], {
                    attributes: true, attributeFilter: ['trans-template']
                });
                if (transclude)
                    transclude(scope, (clone, innerScope) => {
                        let index = 0;
                        trans_template$.pipe((0, operators_1.distinctUntilChanged)()).subscribe((trans_template) => {
                            let map = new Map();
                            while (index < clone.length) {
                                const el = clone[index];
                                if (el.id)
                                    map.set(el.id, index);
                                index++;
                            }
                            if ((trans_template == scope.translateAttr['trans-template']) && scope.transDefault) {
                                trans_template = scope.transDefault;
                            }
                            trans_template = trans_template.replace(/\%\((?<id>[^\)\s]+)\)s/ig, (match, id) => {
                                if (map.has(id)) {
                                    return clone[map.get(id)].outerHTML;
                                }
                                else {
                                    return `%(${id})s`;
                                }
                            });
                            let div = document.createElement('div');
                            div.innerHTML = trans_template;
                            $compile(ng.element(div))(innerScope, (_element) => {
                                instanceElement.append(_element);
                            });
                        });
                    });
            },
        };
    }]);
appModule.config(['WsServiceProvider', 'ConfigServiceProvider', (WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('percentage/');
        ConfigServiceProvider.setDefaultConfig({
        // decimal_places: 2
        });
    }]);
